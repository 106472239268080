.scan-box {
  align-items: center;
  border: 1px solid $gray-200;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 280px;
  justify-content: center;
  position: relative;
  width: 100%;

  div {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 280px;
    overflow: hidden;

    i {
      color: $gray-300;
      font-family: FontAwesome;
      font-size: 120px;
    }
  }

  video {
    height: auto !important;
  }

  &:not(.active) {
    background: $white;
  }
}

.camera-toggle {
  background: $white;
  bottom: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding-top: 15px;
  width: 100%;

  .toggle {
    border-radius: 100px;
    height: 20px;
    margin-top: 3px;
  }
}

.attendee-form {
  display: block;
  height: 329px;
  position: relative;
}

.no-selection {
  align-items: center;
  display: flex;
  height: 325px;
  justify-content: center;
  text-align: center;
}

.attendee-details {
  border: 1px solid $gray-200;
  border-radius: 10px;
  padding: 20px;
  .vl {
    border-left: 1px solid $gray-200;
    min-height: 250px;
  }

  .tag {
    background: $gray-400;
    border: unset;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1rem;
    margin: 0;
    padding: 3px 5px;
  }

  button {
    font-size: 0.9rem;
    margin-right: 0.5rem;
    padding: 3px 10px;

    i {
      font-size: 0.9rem;
      margin: 0 -3px 0 2px;
      padding: 0 5px;
    }
  }
}

.attendee-table {
  margin: auto;

  .v-data-table-header__content {
    span {
      font-weight: 500;
      margin: 0;
    }
  }
  tr {
    background: $white;

    &:first {
      border-bottom-style: 2px solid $s-blue-light;
      font-weight: bold;
    }

    td {
      border-color: $gray-400;
      border-style: solid;
      border-width: 1px;
      padding: 15px 20px;
      i {
        color: $s-green;
        float: left;
        font-size: 1.4rem;
        padding: 15px 0 0 10px;
        &.red {
          color: $s-red;
        }
        &.grey {
          color: $gray-500;
        }
      }
      div {
        font-size: 1rem;
        padding-left: 50px;
      }
    }

    &:nth-child(even) {
      background: $gray-200;
    }
  }
}

.badge-position {
  display: flex;
  justify-content: flex-start;
}

.checkin-badge {
  @include box($white, $gray-800);
  align-items: center;
  background-image: url('../static/images/events/materials/badge-background-1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  border: solid 1px $gray-500;
  border-radius: 5px 5px 10px 10px;
  display: flex;
  flex-direction: column;
  height: 322px;
  margin-bottom: 0;
  padding: 5px;
  width: 220px;
  .qr {
    text-align: center;
    i {
      font-size: 70px;
      border-radius: 5px;
      background-color: white;
      padding: 10px;
    }
  }
  .event-title {
    display: flex;
    background-color: $s-orange;
    border-radius: 0px 0px 10px 10px;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 5px;
    margin: 15px -5px -5px -5px;
    max-height: 80px;
    width: 200px;
  }
}

.scan-header,
.attendee-list-header {
  background: $gray-200;
  border-radius: 5px;
  padding: 1rem;
}

#attendee-products {
  width: 100%;

  tr {
    td {
      border: 1px solid $gray-400;
      font-size: 0.9rem;
      padding: 1px 5px;

      button {
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1rem;
        margin-right: 0;
        padding: 0 10px 0 0;

        i {
          margin: 0 -3px 0 2px;
        }
      }

      .tag {
        border: unset;
        border-radius: 5px;
        font-size: 0.8rem;
        line-height: 1rem;
        margin: 0;
        padding: 3px 10px;

        &.gray {
          background: $gray-300;
          color: $gray-800;
          font-weight: 500;
        }

        &.green {
          background: $s-green-muted;
          color: $s-green;
          font-weight: 500;
        }
      }
      &.title {
        width: 55%;
      }
      &.status {
        width: 25%;
      }
      &.action {
        width: 20%;
      }
    }
  }
}

.product-multiselect {
  .v-field__input {
    background: $white;
    border: 1px solid $s-blue;
    border-radius: 4px;
    height: 50px;
    padding: 2px 10px;
    width: 100%;

    > input {
      display: none;
    }

    .v-select__selection {
      span {
        font-size: 1rem;
        margin: 0;
      }
    }
  }

  .v-field--disabled {
    opacity: 1;
  }

  .v-field__append-inner {
    display: none;
  }

  .v-input__details {
    display: none;
  }

  .v-field--appended {
    padding: 0;
  }

  .v-field__outline {
    --v-field-border-width: 0;
  }
}

.v-ripple__container {
  display: none !important;
}

.v-select__content {
  .v-list,
  .v-theme--light,
  .v-list--density-default,
  .v-list--one-line {
    .v-list-item,
    .v-list-item--link,
    .v-theme--light,
    .v-list-item--density-default,
    .v-list-item--one-line,
    .rounded-0,
    .v-list-item--variant-text {
      &:active,
      &:focus {
        opacity: 0;
      }
      .v-list-item__overlay {
        display: none !important;
      }
      .v-list-item__underlay {
        display: none;
      }
      .v-list-item__prepend {
        margin-right: 1rem;
        .v-selection-control,
        .v-selection-control--density-default,
        .v-checkbox-btn {
          --v-selection-control-size: 22px;
          .v-selection-control__wrapper {
            .v-selection-control__input {
              .mdi-checkbox-blank-outline,
              .mdi,
              .v-icon,
              .notranslate,
              .v-theme--light,
              .v-icon--size-default {
                appearance: none;
              }
              input[type='checkbox'] {
                opacity: 1;
              }
            }
          }
        }
      }
      .v-list-item__content {
        font-family: Arboria;
        font-size: 1rem;
      }
    }
  }
}

.v-input--density-default,
.v-field--no-label {
  --v-field-padding-bottom: 0 !important;
}
