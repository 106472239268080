.search-bar-section {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -5vh;
  height: 300px;
}

.hero-title {
  margin-top: 5vh;
  text-align: center;
  text-shadow: 1px 1px 10px $gray-800;
}

.search-box {
  @include box($white, $gray-800);
  font-size: 1.2rem;
  margin-bottom: 50px;
  padding: 10px 30px 30px 30px;

  .search-textbox {
    float: left;
    padding: 6px 15px 8px 20px;
  }
}

.search-bar {
  border-radius: 0px;
  box-shadow: unset;
  max-width: 95%;
  padding: 20px 0px 0px 0px;
  position: relative;
  width: 700px;

  &.search-nav {
    display: flex;
    width: 600px;
    font-size: 1rem;
    padding: 10px 20px 0px 20px;
    margin: 0;

    .search-text-box {
      max-width: 375px;
    }
  }

  .search-category {
    float: left;
    margin: 0px;
    padding: 10px 0px;
  }

  input[type='text'] {
    border: 1px solid $gray-200;
    border-radius: 100px;
    margin-top: 20px;
    padding: 9px 15px;
    width: 100%;
  }

  .search-button {
    border: 1px solid $s-orange;
    display: block;
    height: 44px;
    padding: 0 13px;
    position: absolute;
    right: 3px;
    top: 43px;
    transition: ease-in-out 0.2s;
    width: 45px;
    .icon {
      margin-left: 2px;
    }

    &.mobile {
      background-color: $s-orange;
      border: none;
      border-radius: 100px;
      position: absolute;
      top: 3px;
      left: 262px;
      width: 44px;
      p {
        color: white;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: unset;
        margin: 0;
      }
    }

    &:hover {
      background: white;
      color: $s-orange;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.1s ease-in-out;
      .icon {
        margin-left: 0;
      }
    }
  }
}

.filter-section {
  top: 20px;
}

.show-filters {
  margin-top: -5vh;
  max-width: 360px;
  padding: 10px 0;
}

.filter-box {
  padding-top: 2.5%;
  position: sticky;
  top: 50px;
}

.hidden {
  display: none;
}

.filter-list-open {
  ul {
    display: block;
  }
}

.filter-list-closed:not(.always-open) {
  .filters {
    display: none;
  }
}

.always-open {
  i {
    display: none;
  }
}

.active-filters {
  display: flex;
  min-height: 50px;

  ul {
    padding-left: 0px;

    li {
      float: left;
      margin-right: 5px;
      list-style-type: none;
    }
  }
}

.filter {
  div {
    display: flex;
    margin-bottom: 0.8rem;

    input {
      display: block;
      float: left;
      margin-right: 5px;

      &:before {
        font-size: 14px;
        height: 19px;
        padding: 2px;
        position: relative;
        top: -3px;
        width: 19px;
      }
    }

    label {
      color: $gray-800;
      display: block;
      float: left;
    }
  }

  .filter-topic {
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 3px 0px;
    position: relative;
  }
}

.selected-filters {
  height: 43px;
  overflow: hidden;
  padding-left: 0px;

  li {
    display: inline-block;
    list-style-type: none;
  }
}

.result-box {
  @include box($white, $gray-800);
  border: 1px solid $gray-200;
  border-radius: 5px;
  box-shadow: 0 0 0;
  margin: 20px 0 -2px 0;
  padding: 10px 10px 10px 20px;
  position: relative;
  transition: 0.2s;
  width: 100%;

  .logo-container {
    align-items: center;
    border-color: $gray-200;
    border-radius: 0 0 5px 0;
    border-style: none solid solid none;
    border-width: 1px;
    display: flex;
    margin: 15px 0 -12px 0;
    padding: 0px 0px 15px 0px;
    transition: 0.2s;

    img {
      display: block;
      margin: 0 auto;
      max-width: 80%;
      width: 350px;
    }
  }

  .content-container {
    border-left: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    border-radius: 5px 0 0 0;
    margin: -12px 0 20px -2px;
    padding: 20px 0 0 20px;
    transition: 0.2s;
  }

  .membership {
    color: $green;
    margin-left: 10px;
    position: relative;
    top: -1px;
  }

  .label-meet-us {
    border-bottom: 1px solid $sun-light;
    color: #030b45;
    display: inline;
    padding: 5px;
    transition: 0.2s;
    width: 100%;

    i {
      color: $sun-light;
      margin-right: 5px;
      transition: 0.2s;
    }

    &:hover {
      border-bottom-color: $s-orange;

      i {
        color: $s-orange;
      }
    }
  }
}

.event-results,
.article-results {
  .search-highlight {
    @include box($white, $gray-800);
    margin-top: 20px;
    padding: 20px;

    .search-image {
      margin-bottom: 10px;

      img {
        border-radius: 20px;
        width: 100%;
      }
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 0px;
  }
}

.show-more {
  background: $white;
  border: 0px;
  border-top: 1px solid $gray-200;
  color: $gray-600;
  display: block;
  font-size: 0.9rem;
  margin: 10px auto 0px auto;
  padding-top: 5px;

  i {
    font-size: 0.7em;
    position: relative;
    top: -1px;
    transition: 0.2s;
  }

  &:hover {
    color: $s-orange;

    i {
      margin-left: 5px;
    }
  }
}

.typeahead-filters {
  padding-left: 0;

  li {
    background-color: #555555;
    color: white;
    border-radius: 20px;
    display: inline-block;
    margin: 0px 5px 5px 0px;
    padding: 0px 7px 0px 0px;
  }

  span.filter-name {
    background-color: #f2be1e;

    &.country {
      background-color: hotpink;
    }
  }
}

@media only screen and (max-width: 991px) {
  .active-filters {
    margin-bottom: 30px;
    min-height: 0px;
  }

  .result-box {
    .logo-container {
      display: none;
    }

    .content-container {
      border-style: none;
    }
  }

  .search-bar {
    width: 425px;

    &.search-nav {
      .search-text-box {
        max-width: 200px;
      }
    }
  }
}
