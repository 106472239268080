.easy-box {
  @include box($white, $gray-800);
  padding: 25px;
  .row {
    display: flex;
  }
  .easy-images {
    img {
      width: 100%;
    }
  }
}

.easy.alert-success {
  background-color: $s-green-muted;
  border: none;
  box-shadow: $s-box-shadow;
  position: fixed;
  z-index: 1;
}

.easy-form {
  width: 75%;
  border-radius: 10px;
  box-shadow: $s-box-shadow-basic;
  padding: 20px 30px;
}

@media only screen and (max-width: 991px) {
  .easy-form {
    width: 100%;
  }
}
