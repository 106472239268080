.cropped-image-upload {
  img {
    object-fit: contain;
    background-color: transparent;
    border-radius: 0.5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    border-color: transparent;
  }

  img.circular {
    background-color: lightgray;
  }

  input[type='file'] {
    display: none;
  }

  .cropped-image-upload-button {
    max-width: 180px;
  }
}

_no_image {
}
