form {
  ul {
    list-style-type: none;
    padding-left: 0;
  }

  &.resource_form {
    label {
      font-size: 1.1rem;
      line-height: 1.6rem;

      input {
        margin: 1px 0.5rem 0.5rem 0;
      }
    }
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='date'],
select,
textarea {
  background: $white;
  border: 1px solid $s-blue;
  border-radius: 4px;
  height: 50px;
  margin-bottom: 0.5rem;
  max-width: 800px;
  outline: none;
  padding: 2px 10px;
  width: 100%;

  &::placeholder {
    color: $gray-600;
  }

  &.narrow {
    width: 120px;
  }

  &.small {
    height: 35px;
  }

  &.gray {
    background: $gray-300;
    border: unset;
    margin-bottom: 0.2rem;
  }

  &:disabled {
    border: 1px solid $gray-500;
  }
}

input[type='text'] {
  &.small {
    font-size: 0.9rem;
    padding: 0 5px;
  }
}

input[type='submit'] {
  @include button($s-orange, $white);
  font-size: 1.2rem;
  margin-top: 20px;
  padding: 7px 20px;

  &.inactive {
    background: $gray-300;
    border-color: $gray-300;
    box-shadow: unset;
    color: $gray-600;
    cursor: default;
  }
}

input[type='checkbox'],
input[type='radio'] {
  appearance: none;

  &:before {
    content: '\f00c';
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $gray-400;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font: var(--fa-font-regular);
    height: 22px;
    margin-right: 3px;
    padding: 3px;
    position: static;
    top: 4px;
    transition: background-color ease-in 0.2s;
    width: 22px;
  }
}

input[type='radio']::before {
  border-radius: 100px;
}

input[type='checkbox']:checked::before,
input[type='radio']:checked::before {
  content: '\f00c';
  background-color: $s-blue;
  border: 1px solid $s-blue;
}

textarea {
  resize: none;
  padding: 8px 10px;
}

label {
  color: $s-blue;
  cursor: pointer;
  display: flex;
  margin-bottom: 4px;
  font-size: 0.9rem;
  line-height: 1.1rem;

  span {
    left: 10px;
    position: relative;
  }

  &:hover ::before {
    border: 1px solid $s-blue;
  }
}

ul {
  &.radio {
    list-style-type: none;
    padding-left: 0.2rem;
    width: 200px;

    label {
      font-weight: 300;
    }
  }
}

.field-errors {
  ul {
    list-style-type: none;
    padding-left: 0;
    li,
    li span {
      color: $red;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
  }
}
