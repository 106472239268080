.pagination {
  margin: 50px auto 20px auto;
  max-width: 490px;
  width: 100%;

  ul {
    display: flex;
    list-style-type: none;
    padding-left: 0px;

    li {
      background: $gray-200;
      border-radius: 100px;
      font-size: 1.2rem;
      padding: 0px 9px;
      margin: 0px 2px;

      a {
        color: $gray-800;

        i {
          font-size: 0.9rem;
        }
      }

      &.active-page {
        background: $s-orange;

        a {
          color: $white;
        }
      }
    }
  }
}
