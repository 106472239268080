@mixin box($background-color, $font-color) {
  background: $background-color;
  color: $font-color;
  border-radius: 5px;
  box-shadow: 0 0.15rem 1.5rem rgba(0, 0, 0, 15%);
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px;
}

@mixin popup($background-color, $font-color) {
  background: $background-color;
  border-radius: 20px;
  color: $font-color;
  left: 50%;
  margin: 0px;
  position: absolute;
  top: 50%;
  transform: translate(
    -50%,
    -50%
  ) !important; // overrides Bootstrap Modal - we might get rid of important when we import Bootstrap JS
}

@mixin block() {
  margin-bottom: 20px;
}

.error-box {
  background: #fbeeca;
  border-radius: 16px;
  padding: 10px 15px;

  i {
    color: $s-blue;

    &.large {
      font-size: 30px;
      padding: 10px 0;
    }
  }
}

.social-box {
  background: $gray-300;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 15px;
}
