body {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;

  &.mobile_application {
    min-height: 80vh !important;
  }

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: $gray-200;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: 10px;
  }
}

.full-width-banner {
  width: 100%;

  &.blue-muted {
    background: $s-blue-muted;
  }
}

.container {
  margin-bottom: 5vh;
  margin-top: 5vh;
  scroll-margin-top: 125px;
}

.center {
  margin: 0px auto;
}

.flex {
  display: flex;
}

.scroll-horizontal {
  flex-wrap: unset;
  overflow-x: auto;
  white-space: nowrap;

  div {
    white-space: normal;
  }
}

.row {
  &.center {
    text-align: center;
  }
}

#cookie-jar {
  background: $white;
  bottom: 0;
  display: none;
  position: sticky;
  z-index: 100;

  .container {
    margin: 30px auto;

    #cookie-info {
      padding-right: 20px;
    }

    #cookie-options {
      display: none;

      .cookie-form {
        display: flex;
        justify-content: space-between;
        padding-left: unset;

        .cookie-type {
          max-width: 32%;
          padding: 0 5px;
          text-align: center;

          .cookie-switch {
            float: unset;
            height: 1.5rem;
            margin-left: unset;
            width: 2.5rem;

            &:checked {
              background-color: $s-orange;
              border: 1px solid $white;
            }

            &:focus {
              border-color: $gray-500;
              box-shadow: unset;

              &:not(:checked) {
                background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
              }
            }
          }
        }
      }
    }

    #cookie-buttons {
      align-items: baseline;
      align-content: center;
      border-left: 1px solid $gray-200;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 20px 0;
    }

    a {
      display: inline;
      padding: 7px 20px;
      text-decoration: underline;
    }

    #allow-selection {
      display: none;
    }

    .close {
      color: $s-blue;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 20px;
      text-align: center;
      width: 20px;
    }
  }
}

input {
  margin-right: 5px;
}

#login-warning {
  background: rgba(3, 11, 69, 0.8);

  .solarplaza-logo {
    margin: 0 auto 30px auto;
    max-width: 80%;
    width: 220px;
  }

  .modal-dialog {
    @include box($white, $gray-800);
    left: 50%;
    max-width: 700px;
    padding: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    width: 90%;
  }
}

.only-mobile {
  display: none;
}

.cookiealert {
  position: fixed;
  bottom: -16px;
  left: 0;
  width: 100%;
  max-height: 200px;
  height: 175px;
  z-index: 999;
  border-radius: 0;
  transition: all 500ms ease-out;
  color: $gray-800;
  background: #ffffff;

  .show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    transition-delay: 1000ms;
  }
}

.privacy-policy {
  td {
    min-width: 165px;
  }
  .table-striped > tbody > tr:nth-child(2n + 1) > td,
  .table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: $gray-100;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-tablet {
    display: none;
  }

  .only-tablet {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 0 20px;
  }

  .hide-on-mobile {
    display: none;
  }

  .only-mobile {
    display: block;
  }

  #cookie-jar .container #cookie-options .cookie-form {
    flex-direction: column;

    .cookie-type {
      max-width: unset;
    }
  }
}
