.modal-content {
  background: unset;
  border: 0px;

  .modal-body {
    padding: 0px;
  }
}

.modal-backdrop {
  background: $s-blue;

  &.show {
    opacity: 0.6;
  }
}

.modal-footer {
  border-top: none;
  padding: 0;
}

// For the Vue-modal popups
.s-modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(3, 11, 69, 0.7);
  z-index: 2000;
}

.s-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-modal-popup {
  display: grid;
  grid-template-rows: 40px auto 40px;
  grid-template-areas: 'header' 'body' 'footer';
  grid-gap: 10px;

  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 15%);
  padding: 40px;
}

.s-modal-header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .close-button {
  }
}

.s-modal-body {
  grid-area: body;
  //overflow-y: scroll;
}

.s-modal-footer {
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    width: 200px;

    &.cancel {
      width: 100px;
    }
  }
}

// For the transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
