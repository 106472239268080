.s-flex-horizontal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.s-flex-horizontal-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.s-flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.s-flex-vertical-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
