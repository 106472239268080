@mixin button($background-color, $font-color) {
  background: $background-color;
  border: solid 2px $background-color;
  border-radius: 100px;
  color: $font-color;
  font-weight: 600;

  &:hover:enabled {
    background-color: white;
    border: solid 2px $background-color;
    color: $s-orange;
  }
}

button.formkit-input {
  @include button($s-orange, $white);
}

button,
.button {
  cursor: pointer;

  span {
    color: inherit;
  }

  &.orange {
    @include button($s-orange, $white);

    &.outline {
      &:hover:enabled {
        background-color: $s-orange;
        color: $white;
      }
      background-color: $white;
      color: $s-orange;
    }
  }

  &.green {
    @include button($s-green, $white);
    &:hover:enabled {
      color: $s-green;
    }
  }

  &.red {
    @include button($s-red, $white);
    &:hover:enabled {
      color: $s-red;
    }
  }

  &.blue-light {
    @include button($s-blue-light, $white);
    &:hover {
      color: $s-blue-light;
    }
  }

  &.white {
    @include button($white, $s-blue-light);

    &.small {
      box-shadow: unset;
      border: 1px solid $s-blue-light;
      color: $s-blue-light;

      i {
        color: $s-blue-light;
      }
    }

    &:hover:enabled {
      color: $white;
      background-color: $s-blue-light;
      border-color: $s-blue-light;

      i {
        color: $white;
      }
    }
  }

  &:disabled {
    background: $gray-200;
    color: $gray-600;
    border: solid 2px $gray-200;
    cursor: default;
  }

  &.tiny {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2rem;
    padding: 0 3px 0 10px;

    i {
      font-size: 0.6rem;
      margin-left: -5px;
      transition: 0.2s;
    }

    &:hover:enabled {
      background: $s-blue-light;
      color: $white;

      i {
        margin-left: 0px;
      }
    }
  }

  &.small {
    font-size: 1rem;
    font-weight: 400;
    padding: 5px 15px;

    i {
      font-size: 0.8rem;
      margin-right: 5px;
    }

    &.cancel {
      font-weight: normal;
      box-shadow: none;

      &:hover {
        text-decoration: underline;
        color: $s-orange;
      }
    }
  }

  &.medium {
    font-size: 1.2rem;
    padding: 7px 20px;
    margin: 0 0 5px 0;

    i {
      font-size: 1rem;
      margin-left: 5px;
      transition: 0.2s;
    }

    &:hover:enabled {
      i {
        margin-left: 15px;
      }
    }
  }

  &.cancel {
    @include button($white, $s-orange);
    font-weight: normal;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
      color: $s-orange;
    }
  }

  &:focus {
    outline: none;
  }
}

.category {
  background: $gray-200;
  border-radius: 5px;
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
}

.tag {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid $gray-800;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 5px 20px;
  margin: 0 5px 5px 0;
  white-space: nowrap;
  transition: 0.2s;

  i {
    font-size: 1.1rem;
    margin-left: 5px;
  }

  &.small {
    border: 1px solid $gray-200;
    color: $gray-600;
    margin: 0 0 3px 0;
    padding: 4px 6px;

    i {
      font-size: 0.9rem;
    }
  }

  span {
    margin-bottom: 0;
  }

  &:hover {
    border-color: $gray-500;
    color: $gray-500;

    span {
      color: $gray-500;
    }
  }
}

.social-icons {
  img {
    float: left;
    margin: 0px 15px 15px 5px;
    width: 50px;
    transition: 0.3s ease-in-out 0s;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

//.bookmark-event, .bookmark-organization, .bookmark-article {
.bookmark {
  border-radius: 50px;
  color: $s-blue;
  cursor: pointer;
  display: initial;
  float: right;
  position: relative;
  z-index: 100;

  &.active {
    border-color: $sun-light;
  }

  &.with-text {
    border: 1px solid $gray-200;
    padding: 0 2px 0 10px;

    .icon {
      border: unset;
      height: 27px;
      margin: unset;
      padding-top: 6px;
      width: 27px;
    }
  }

  span {
    padding-right: 2px;
    transition: 0.2s;
  }

  .icon {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 50%;
    color: $sun-light;
    float: right;
    height: 33px;
    margin: 5px 5px 0 0;
    padding-top: 9px;
    text-align: center;
    transition: 0.2s;
    width: 33px;
  }

  &.network-results {
    right: 0px;
  }

  &.detail-page {
    float: right;
    position: relative;
  }

  &.bookmark-nav {
    float: right;
    padding-top: 20px;
    position: inherit;
  }

  &:hover {
    .icon {
      color: $s-orange;
    }
  }
}

.icon-button {
  color: $s-orange !important;
  border-radius: 50%;
  width: 32px !important;
  height: 32px !important;
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  &:hover {
    background-color: $gray-200;
  }

  &.icon-button-top-right {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.app-button {
  background: $s-orange-gradient;
  border: none;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  &.secondary {
    background: white;
    border: 2px solid $s-orange;
    color: $s-orange;
  }
}

.feedback-side {
  position: fixed;
  right: -35px;
  top: 50%;
  transform: rotate(-90deg);
  z-index: 1030;
  .feedbackbtn {
    border-radius: 5px 5px 0 0;
    color: white;
    padding: 0 7px 4px 7px;
    font-size: 1.1rem;
  }
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  i {
    color: $s-orange;
    font-size: 3rem;
    width: 50px;
    &:hover {
      color: $sun-medium;
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
  background-color: $gray-300;
  border-radius: 25px;
  padding: 5px;
  width: 44px;
  height: 23px;
  transition: 0.3s;
  .switch {
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $gray-400;
    margin: 0px;
    width: 16px;
    height: 16px;
    transition: 0.3s;
  }
}

.toggle-active {
  background-color: $s-green;
  transition: 0.3s;
  .switch {
    transform: translate(18px, 0px);
    transition: 0.3s;
  }
}
