.profile-picture {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}

img.small {
  height: 80px;
  width: 120px;
}

.circular {
  &.small {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  border-radius: 50%;
  object-fit: cover;
}
