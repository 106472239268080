#notifications {
  margin-top: -40px;

  a {
    color: #343a40;
  }

  .notifications-list-item {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 0px;

    .image {
      flex: 0 0 50px;
      margin-right: 1rem;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        border: solid 1px lightgray;
        border-radius: 50%;
      }
    }

    .content {
      flex: 1 1 auto;
      align-content: center;

      .title {
        display: flex;
        flex-display: row;
        justify-content: space-between;
      }

      .text {
        display: flex;
        flex-display: row;
        align-content: center;
      }
    }

    .unread {
      align-self: center;
      flex: 0 0 0.8rem;

      margin-left: 1rem;

      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: $s-blue-light;
    }
  }

  .notifications-empty-message {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .notification-icon {
    font-size: 10rem;
    transform: rotate(2deg);
    margin-top: 2rem;
    color: rgb(202, 203, 204);
    filter: drop-shadow(2px 2px 2px #d7d7d7);
    text-align: center;
    width: 100%;
  }
}
