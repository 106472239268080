.tag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border: 1px solid $s-blue;
  border-radius: 4px;
  min-height: 50px;
  width: 100%;

  &.vertical {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: unset;
    //justify-content: flex-start;
  }

  input[type='text'] {
    border: none;
    height: unset;
    padding: 0;
    margin-bottom: 0;
    width: unset;
  }

  input[type='checkbox'] {
    //border: solid 1px $s-orange;
    padding: 0;
    margin-bottom: 0;
  }

  span {
    margin-bottom: 0;

    &:focus {
      outline: none;
    }
  }

  .tag-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
    padding: 8px 20px 12px;
    height: 40px;
  }

  .selected-tag {
    color: $gray-800;
    background-color: $gray-200;
    border: solid 1px $gray-800;
    border-radius: 20px;

    i {
      margin-top: 4px;
      color: $gray-800;
    }

    input {
      background-color: transparent;
    }
  }
}

.tag-input-search-results {
  background-color: $white;
  max-height: 600px;
  padding: 0;
  position: absolute;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px 10px;
  border-radius: 6px;
  z-index: 100;

  li {
    list-style-type: none;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }

    &.selected {
      background-color: $dropdown-link-active-bg;

      span {
        color: white;
      }
    }
  }
}

.s-file-upload {
  position: relative;
  width: 400px;
  height: 240px;
  background-image: url('../static/images/default-organization.png');
  background-repeat: no-repeat;
  background-position: center;
  border: solid 2px white;

  &.drag-target {
    border: dashed 2px grey;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    width: inherit;
    height: inherit;
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block;
  }
}

.click-catch-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vh;
}

.input-text {
  background: $white;
  border: 1px solid $s-blue;
  border-radius: 4px;
  height: 50px;
  margin-bottom: 0.5rem;
  outline: none;
  padding: 12px 10px;
  width: 100%;
  display: inline-block;
  //vertical-align: middle;
}

.badge-filters {
  margin-top: 0.5rem;

  &.horizontal-scroll {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    overflow-x: scroll;
    white-space: nowrap;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  a.badge-filter,
  button.badge-filter {
    border-radius: 40px;
    font-size: 0.9rem;
    line-height: 1rem;
    padding: 2px 10px;
    margin: 0 0.15rem;

    &:hover,
    &.active {
      background-color: $s-red;
      color: white;
    }

    &:not(.active) {
      background-color: lightgray;
      border-color: lightgray;
      color: gray;

      &:hover {
        color: gray;
        background-color: white;
      }
    }
  }
}
