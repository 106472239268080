.consultancy-button {
  @include button(#fbaf28, $white);
  box-shadow: unset;
  display: inline-block;
  font-size: 1.2rem;
  margin: 0px;
  padding: 14px 20px;
  width: unset;
  z-index: 10;
  transition: all 0.2s;

  i {
    font-size: 1rem;
    margin-left: 5px;
    transition: 0.2s;
  }

  &:hover {
    color: #fbaf28;
    i {
      margin-left: 15px;
    }
  }
}

.brochure-btn {
  @include button(#fbaf28, $white);
  box-shadow: unset;
  display: inline-block;
  transition: all 0.2s;
  overflow: hidden;
  position: relative;

  .btn-invisible {
    position: absolute;
    top: -100%;
    left: 83%;
  }

  &:hover {
    color: $s-consultancy;
    border-color: $s-consultancy;
    overflow: hidden;

    .btn-invisible {
      top: 40%;
    }

    .btn-visible {
      transform: translateY(230%);
    }

    i {
      margin-left: 5px;
    }
  }
}

.consultancy-nav {
  background: white;
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 10%);
  margin: 0px auto;
  padding: 25px 3%;
  width: 100%;

  .navbar-toggler-icon {
    background: none;
    display: inline;
    margin: 0px 15px;
  }

  .navbar-nav {
    margin: 0px auto;
  }

  .nav-link {
    color: $gray-800;
    border-bottom: $white solid 2px;
    height: 45px;
    margin: 5px 15px 0px 15px;
    padding: 5px 5px -5px 0px;
    -webkit-transition: 0.3s ease-in-out; /* For Safari 3.0 to 6.0 */
    transition: 0.3s ease-in-out; /* For modern browsers */

    &.consultancy {
      button {
        margin-top: -4px;
        padding: 5px 10px 7px 20px;
        &:hover {
          color: #fbaf28;
        }
      }
    }

    &:hover:not(.consultancy) {
      color: #073763;
      border-bottom: #073763 solid 2px;
      cursor: pointer;
    }
  }
}

.consultancy-hero {
  background: linear-gradient(0deg, rgba(7, 55, 99, 0.3), rgba(7, 55, 99, 0.3)),
    url('../static/images/consultancy/solarplaza-consultancy-main-visual.jpg');
  color: $s-consultancy;
  padding: 10px;

  .logo {
    align-self: center;
    background: $white;
    max-width: 450px;
    padding: 30px 40px 40px 40px;
    text-align: center;

    img {
      margin: 0px auto;
      width: 100%;
    }
  }

  .header-block {
    background: $s-consultancy;
    clip-path: polygon(70% 0, 100% 3%, 100% 100%, 70% 97%, 0 100%, 0 3%);
    padding: 50px 45px 40px 45px;
    p {
      color: $white;
      font-weight: 500;
      b {
        color: #fbaf28;
      }
    }
  }
}

.statement {
  border-color: #fbaf28;
  border-style: solid;
  border-width: 2px 0px 2px 0px;
  margin: 40px auto;
  text-align: center;

  p {
    line-height: 2.6rem;
    margin: 20px 0px;
  }
}

.consultancy-icon {
  margin: 15px auto;
  width: 100%;
  img {
    max-width: 130px;
  }
}
.dark-blue {
  img {
    max-width: 200px;
  }
}

.resources-section {
  scroll-margin-top: 125px;

  h2 {
    color: #073763;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 3.4rem;
    margin: 40px 0px;
    text-align: center;
  }
}

.projects-section {
  background: #073764;
  clip-path: polygon(70% 0, 100% 3%, 100% 100%, 70% 97%, 0 100%, 0 3%);

  .project-button {
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
    padding: 15px;
    border-radius: 5px;
    background-color: #fbaf28; //#fbaf28
    color: white;
    text-align: center;
    transition: 0.2s all;
    .btn-visible {
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .btn-invisible {
      display: none;
    }

    &:hover {
      background-color: white;
      .btn-visible {
        display: none;
      }
      .btn-invisible {
        text-align: center;
        font-weight: 500;
        display: flex;
        justify-content: center;
        color: $s-consultancy;
      }
    }
  }
}

.why-section {
  background: #073764;
  clip-path: polygon(70% 0, 100% 3%, 100% 100%, 70% 97%, 0 100%, 0 3%);

  .statement {
    border-color: #fbaf28;
    border-style: solid;
    border-width: 3px 0px 3px 0px;
    margin: 40px auto;
    text-align: center;

    h3 {
      color: $white;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.1rem;
      margin: 20px 0px;
    }
  }
}

.expand-box {
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
  padding: 0px;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fbaf28;
    min-height: 120px;
    position: relative;
    width: 100%;
    color: $white;
    line-height: 2.5rem;
    text-align: center;
  }

  .description {
    display: none;
    padding: 20px;

    p {
      color: $white;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.7rem;
      text-align: center;
    }
  }

  i {
    font-size: 1.5rem;
    margin-left: 15px;
    position: relative;
    top: 0px;
    transition: 0.2s;
  }

  &:hover {
    border-color: #fbaf28;
    i {
      top: 5px;
    }
  }
}

.clients-section {
  text-align: center;
  .row {
    align-items: center;
    justify-content: center;

    .logo-overview {
      display: contents;

      .client-logo {
        float: left;
        padding: 5px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.impact-section {
  background: #073764;
  clip-path: polygon(70% 0, 100% 3%, 100% 100%, 70% 97%, 0 100%, 0 3%);

  .impact-box {
    margin: 20px 0px;

    .number {
      p {
        padding-bottom: 20px;
        color: #fbaf28;
        font-size: 6rem;
        font-weight: 500;
      }
    }
  }
}

.region-section {
  background-image: url('../static/images/consultancy/consultancy-map.png');
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  min-height: 550px;
}

.support-section {
  p {
    &.description {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .name {
    color: white;
    display: inline-block;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 2.1rem;
    margin-top: 40px;
    padding: 20px;
    border-radius: 20px 20px 20px 0;
  }

  .yellow {
    background-color: #fbaf28;
    border: none;
    &:hover {
      color: white;
    }
  }

  .blue {
    background-color: #073764;
    border: none;
    &:hover {
      color: white;
    }
  }

  .contact-box {
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
    margin: 0px;
    padding: 20px;
    border-radius: 10px;

    .contact-picture {
      background-color: $gray-200;
      background-position: center center;
      background-size: 250px;
      height: 200px;
      max-width: 200px;
      width: 100%;
    }

    .email {
      color: $s-consultancy;
      margin: 10px 0px 30px 0px;
      font-size: 1.3rem;
      i {
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }

    .bio {
      margin-top: 20px;
      min-height: 65px;
      p {
        color: $s-consultancy;
        font-weight: 300;
        font-size: 1.3rem;
      }
    }
  }
}

.job-title {
  display: inline-block;
  margin: 0 0 0 25px;
}

.brochure-section {
  background: #073764;
  clip-path: polygon(70% 3%, 100% 0, 100% 100%, 0 100%, 0 0);
  padding-bottom: 40px;
  max-height: 500px;

  .line {
    color: #fbaf28;
  }

  .circle {
    background-color: rgba(146, 165, 184, 0.1);
    border-radius: 50%;
    display: inline;
    float: right;
    position: relative;
    top: 0px;
    width: 350px;
    height: 350px;
    z-index: -1;
    img {
      width: 200px;
      float: right;
      position: absolute;
      top: 15%;
      left: 25%;
    }
  }
}

.consultancy-slideshow {
  height: 350px;
  max-width: 100%;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .consultancy-hero {
    .description {
      clip-path: polygon(100% 0, 100% 100%, 70% 97%, 0 100%, 0 0);
    }
  }
  .brochure-section {
    .circle {
      display: flex;
      left: 90px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .region-section {
    background-image: none;
    p {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .brochure-section {
    padding: 20px;
    .circle {
      display: none;
    }
  }

  #projects {
    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .services-section {
    .service {
      .description {
        text-align: center;
      }
    }
  }
}
