.virtual-table {
  border: 2px solid $s-blue-light;
  margin: auto;
  max-width: 800px;

  tr {
    background: $white;
    th {
      background: $white;
      border-color: $s-blue-light;
      border-style: solid;
      border-width: 0 1px 0 1px;
      padding: 10px 5px;
      width: 50%;
    }
    td {
      border-color: $s-blue-light;
      border-style: solid;
      border-width: 0 1px 0 1px;
      padding: 15px 20px;
      i {
        color: $s-green;
        float: left;
        font-size: 1.4rem;
        padding: 15px 0 0 10px;
      }
      div {
        font-size: 1rem;
        padding-left: 50px;
      }
    }

    &:nth-child(odd) {
      background: $gray-200;
    }
  }
}

.faq {
  .question-box {
    border: 1px solid $s-blue-light;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin: 1rem 0;
    width: 100%;

    .expand {
      background: $s-blue-light;
      display: flex;
      float: left;
      margin-right: 20px;
      padding: 7px 10px;

      i {
        align-self: center;
        color: $white;
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }

    .question {
      align-self: center;
      padding: 10px 0;
    }
  }

  .answer {
    display: none;
    padding: 0 20px 0 60px;
  }
}
