.resource-box {
  @include box($white, $gray-800);
  border: 2px solid $gray-200;
  border-radius: 5px;
  box-shadow: unset;
  display: block;
  height: 600px;
  margin-bottom: 20px;
  max-width: 360px;
  padding: 10px;
  position: relative;

  .resource-visual {
    background-position: center;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    height: 200px;
  }

  .resource-type {
    background: $s-blue;
    margin-bottom: 20px;
    padding: 3px 10px;
    width: 100%;
  }

  .webinar-status {
    background: #f9d5ca;
    display: inline-block;
    padding: 0 5px 1px 10px;
    position: relative;

    &.recordings {
      background: $gray-200;
      float: right;
      margin-top: -43px;
    }
  }

  .resource-title {
    display: -webkit-box;
    min-height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .teaser {
    display: -webkit-box;
    min-height: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .tag-section {
    bottom: 0;
    height: 58px;
    overflow: hidden;
    position: relative;

    .tag {
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        background: $s-blue;
        color: $white;
      }
    }
  }
}

.resource-background {
  background-color: #f4f4f4;
  .image-wrap {
    position: absolute;
    width: 100%;
    height: 400px;
    opacity: 0.5;
  }
}

.content-background {
  @include box($white, $gray-800);
  border: 2px solid $gray-200;
  border-radius: 5px;
  box-shadow: unset;
  display: block;
  margin-bottom: 120px;
  padding: 20px;
  position: relative;
  top: 100px;
}

.tag-section-resource {
  bottom: 0;
  margin-bottom: -15px;
  overflow: hidden;
  position: relative;
  display: inline;

  .tag {
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: $s-blue;
      color: $white;
    }
  }
  section {
    display: inline-block;
  }
}

.reading-time {
  float: right;
}

.resource-line {
  display: block;
  min-width: 100%;
}

.form-block {
  @include block();
  @include box($white, $gray-800);
  width: 100%;

  img {
    padding: 5%;
    width: 100%;
  }

  .form-box {
    display: block;
    margin: 0 auto;
    max-width: 450px;
    padding-top: 20px;
    width: 100%;
  }
}

.image-block {
  @include block();
}

.speakers-block {
  @include block();
  margin: 20px 0 0 0;
}

.event-block {
  border-top: 1px solid $gray-500;
  margin-bottom: 40px;
  padding-top: 20px;

  img {
    margin-bottom: 10px;
    max-width: 50vw;
    min-width: 200px;
    width: 100%;
  }

  .content {
    display: table;
    min-height: 40px;

    p {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.text-block {
  @include block();
  margin-bottom: 40px;
}

.video-block {
  @include block();
  margin-bottom: 40px;
}

.sub-chapter {
  margin-left: 20px;
  font-weight: 400;
  font-size: 0.9rem;
}

.webinar-block {
  @include block();
  @include box($white, $gray-800);
  margin-top: 10px;
  padding: 20px;
  width: 100%;

  .container {
    position: relative;

    .thumbnail {
      display: block;
      height: 135px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}

.access-file-block {
  @include block();
  @include box($white, $gray-800);
  max-width: 600px;

  img {
    max-width: 100%;
  }

  .login-message {
    padding: 15px 20px;
  }
}

.webinar-summary-box {
  @include box($white, $gray-800);
  padding: 5% 3%;

  .resource-visual {
    height: 200px;
  }
}

.resource-date {
  display: inline;
  margin: 0px 20px 0px 20px;
  text-transform: capitalize;
}

.resource-login {
  text-align: center;
}

.resource-access {
  padding: 20px;
  margin: 0px 5px;

  &.register {
    background: $s-blue-light;
  }

  &.optin {
    background: $s-blue-muted;
  }

  .image-background {
    background: $white;
    float: right;
    margin: 20px 50px 0 0;
    max-width: 90%;
    width: 300px;

    img {
      transform: translate(20px, -20px);
      width: 100%;
    }
  }

  .text {
    margin-top: 20px;
  }
}

.article-contact {
  max-width: 140px;
  max-height: 140px;
}

.resource-disclaimer {
  background-color: $gray-200;
  border-radius: 5px;
  p {
    padding: 15px;
  }
}

@media only screen and (max-width: 1400px) {
  .webinar-block {
    text-align: center;
    .container {
      display: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  .webinar-block {
    text-align: left;

    .container,
    .thumbnail {
      display: block;
      height: 125px;
      margin: 0 0 10px 0;
      position: relative;
    }
  }

  .number-results {
    max-width: 360px;
  }

  #filters {
    max-width: 360px;
  }

  .reading-time {
    display: block;
    float: left;
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .resource-box {
    .resource-title {
      height: unset;
    }
  }

  .webinar-block .container {
    margin: 5px 0 0 0;
  }
}

@media (min-width: 576px) {
  .collapse.collapse-mobile {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

@media (max-width: 1194px) {
  .resource-tags-title {
    margin-bottom: -15px;
  }
}
