.organization-type-badge {
  background: $s-blue-muted;
  color: $s-blue-light;
}

.track-label {
  background: $s-orange-gradient;
  border-radius: 5px;
  color: white;
  display: inline;
  float: right;
  font-weight: bold;
  padding: 5px;
  width: 100%;
  max-width: fit-content;
}

.current-sessions-scrollbar {
  overflow-x: visible;
  margin: -10px;
  .scrollable {
    padding: 10px;
    white-space: normal;
    .card {
      display: inline;
      width: fit-content;
      width: 100%;
      min-width: 100%;
      margin-right: 10px;
    }
  }
}
