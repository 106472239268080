.newsletter-popup {
  @include popup($white, $gray-800);
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
  max-width: 400px;
  padding: 20px;
  text-align: center;
  width: 90%;
}

.newsletter-banner {
  background: #124491;
  display: block;
  height: 100%;
  min-height: 500px;
  position: relative;
  width: 100%;
  z-index: -1;

  .newsletter-banner-visual {
    background-image: url('../static/images/newsletters/top_banner2.jpg');
    background-position: left bottom;
    clip-path: ellipse(50% 150% at 0% 50%);
    height: 100%;
    min-height: 500px;
    position: absolute;
    width: 100%;
  }

  .newsletter-main-title {
    display: block;
    padding: 0px 20px 20px 40px;
    height: 50vh;
    margin: none;
    max-height: 500px;
    position: relative;
  }
}

.newsletter-box {
  @include box($white, $gray-800);
  box-shadow: none;
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: 180px;
  padding: 20px;
  width: 100%;

  .about-image {
    border-radius: 20px;
    height: 200px;
    margin: 20px;
    width: 100%;
  }

  .about-title {
    margin: 15px 0px;
  }

  .about-description {
    padding: 0px 10px;
  }
}

.program-newsletter-box {
  .row {
    display: flex;
    justify-content: space-between;
  }
}

.sun-logo {
  img {
    margin: 0px auto;
    max-width: 400px;
    width: 100%;
  }
}

.what-to-expect-section {
  background: #cbe0f3;
  margin-top: -5vh;
  padding-top: 10px;
}

.newsletter-visual {
  img {
    width: 100%;
    padding: 10px;
  }
  max-width: 60%;
  display: inline;
  float: right;
}

@media only screen and (max-width: 992px) {
  .newsletter-banner {
    min-height: 600px;
    .newsletter-banner-visual {
      min-height: 600px;
    }
  }
  .newsletter-visual {
    margin: 5px 35px;
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .newsletter-banner-visual {
    display: none;
  }

  h3 {
    margin-left: 10px;
  }

  h4 {
    margin-left: 10px;
  }

  p {
    margin-left: 10px;
  }
}
