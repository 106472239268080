.event-card {
  box-shadow: $s-box-shadow-light;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 10px;

  & > div {
    height: 200px;
    padding: 0;

    &.content {
      align-items: center;
      display: flex;
      padding: 0 25px;

      .tag {
        background: $s-blue-muted;
        color: $s-blue;
        border: unset;
        border-radius: 50px;
      }

      &:hover {
        span {
          color: inherit;
        }
      }
    }

    .visual {
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      height: 200px;
      max-width: 500px;
      position: relative;
      width: 100%;
      overflow: hidden;

      .logo {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 3px;
        left: 50%;
        padding: 5px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
          height: 70px;
        }
      }
    }
  }
}
