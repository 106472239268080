.organization-box {
  @include box($white, $gray-800);
  border: 2px solid $gray-200;
  border-radius: 5px;
  box-shadow: unset;
  display: block;
  min-height: 280px;
  margin-bottom: 20px;
  max-width: 360px;
  padding: 10px;
  position: relative;

  .logo {
    width: 100%;
  }
}

.organization-tags {
  margin: 20px 0;

  .tag {
    background: $gray-200;
    border: 0;
    border-radius: 5px;
    margin: 0 4px 4px 0;
    padding: 2px 8px;

    span {
      color: $s-blue;
    }

    i {
      font-size: 13px;
      margin: 0 3px 0 1px;
      position: relative;
    }
  }

  .products,
  .geographies,
  .offices {
    border: 1px solid #cbe0f3;
    margin: 15px 0 0 0;
    padding: 10px 0 10px 10px;

    i {
      font-size: 8px;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
  }
}

.organization-tile {
  border: 2px solid $gray-200;
  border-radius: 5px;
  float: right;
  padding: 40px;
  max-width: 400px;
  margin-bottom: 10px;

  .organization-logo {
    position: relative;
    margin-bottom: 20px;
    min-height: 40px;

    .logo {
      display: block;
      width: 320px; // aspect ration 400*240 * 80%
      height: 192px;
      margin: 0 auto;
    }
  }

  i {
    float: left;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 20px;
  }
}

.organization-geographies,
.organization-offices {
  border: 2px solid $gray-200;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 30px;
}

.activity-container {
  flex-wrap: unset;
  height: 200px;
  margin-bottom: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
  white-space: nowrap;

  .activity {
    background: $gray-200;
    border-radius: 0 50px 50px 0;
    border-right: 5px solid $white;
    color: $s-blue;
    display: inline-table;
    height: 180px;
    padding: 10px;
    position: relative;
    white-space: normal;
    width: 120px;

    &.organization-activity {
      background: $s-orange;

      p {
        color: $white;
        font-weight: bold;
        right: 50%;
      }
    }

    p {
      min-width: 100px;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%) rotate(-90deg);
      max-width: 140px;
      min-width: 120px;
    }

    &:not(:first-child) {
      margin-left: -60px;
      width: 160px;

      p {
        right: 35%;
      }
    }

    &:last-child {
      border-radius: 20px;
      margin-left: 20px;
      width: 120px;

      p {
        right: 50%;
      }
    }
  }
}

.dashboard-bookmarked,
.attended-events,
.featured-speakers,
.knowledge-contributions {
  flex-wrap: unset;
  margin-bottom: 40px;
  overflow-x: auto;
  white-space: nowrap;

  .event-label {
    background: $gray-200;
    margin-bottom: -5px;
    padding: 3px 3px 8px 3px;
    text-align: center;

    &.blue {
      background: #c5dbf2;
    }
  }

  div {
    white-space: normal;
  }

  & > div {
    display: inline-block;
    float: none;
  }

  .featured-speaker {
    border: 2px solid $gray-200;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-height: 155px;
    overflow: hidden;
    position: relative;

    .logo {
      padding: 10px 10px 0 0;
      position: absolute;
      text-align: right;
      top: 0;
      width: 100%;

      img {
        height: 34px;
      }
    }

    .speaker-image {
      height: 155px;

      img {
        border-radius: 5px 60px 80px 5px;
        display: block;
        margin: 0 auto 0 -15px;
        position: relative;
        width: 155px;
      }
    }

    .content {
      padding: 20px;
      position: relative;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);

      p {
        height: 50px;
      }

      .date-location {
        margin-bottom: -7px;
        padding: 0 0 0 0;
        position: absolute;
        width: 100%;

        p {
          margin-right: 10px;
        }

        i {
          margin-right: 5px;
        }
      }
    }
  }
}

#organization-editor {
  z-index: 1100;
}

.geo-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
    border-radius: 12px;
    border: solid 2px $gray-200;

    &.selected {
      border: solid 2px $s-orange-muted;
    }
  }
}
