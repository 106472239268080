.event-main-visual {
  display: flex;
  align-items: center;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  justify-content: center;
  transition: all 0.25s;
  width: 98%;
  -webkit-transition: all 0.25s;

  &:hover {
    transform: scale(1.015);
  }
  img {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    max-width: 65%;
    padding: 10px;
    width: 100%;
  }
}

.prefix {
  font-size: 1.3rem;
}
.main {
  font-size: 1.5rem;
}
.snippet {
  font-size: 1rem;
}

.event-info {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 10px 0px;
}

.divide {
  border-top: 1px solid $gray-500;
  margin: -10px 0px 5px 0px;
  width: 95%;
}

.event-horizontal {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  margin: 20px 0px;
  min-width: 50%;

  .event-main-visual {
    height: 100%;
  }
  .event-info {
    min-width: 50%;
    margin: 0px 0px 0px 25px;
  }
}

@media only screen and (max-width: 576px) {
  .event-horizontal {
    height: 100%;
    flex-direction: column;
  }
  .event-main-visual {
    max-height: 30vh;
  }
  .event-info {
    min-width: 100%;
    padding: 10px 0px;
  }
}
