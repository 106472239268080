
.add-office-form {
  display: grid;
  grid-template-columns: auto 40px;
}

.button-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.office-card {
  display: grid;
  grid-template-columns: auto 40px;
  margin-top: 10px;
  border: 2px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
}
