body.registration-modal {
  background-image: url(https://assets.solarplaza.com/events/main-visuals/Balkans_22_-_Main_Visual_Web.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.container.account {
  margin-bottom: 0;
  margin-top: 0;
}

.account-box {
  @include box($white, $gray-800);
  display: block;
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;

  &.login,
  &.register {
    form,
    .content {
      margin: 0 auto;
      max-width: 300px;
      width: 90%;

      button,
      input[type='submit'] {
        width: 100%;

        &.white {
          background: $white;
          box-shadow: unset;
        }
      }
    }

    .content {
      max-width: 400px;

      button {
        display: block;
        margin: 0 auto;
        max-width: 300px;
      }
    }
  }

  &.login {
    width: 450px;
  }

  &.register {
    form {
      display: grid;
      max-width: 400px;
    }

    #id_kind {
      display: inline-block;
      list-style-type: none;
      margin: 0 auto;
      padding: 0px;
      max-width: 350px;
      width: 100%;

      label {
        cursor: pointer;
        font-size: 1.1rem;

        input {
          margin-right: 10px;
        }

        input[type='radio']:checked::before {
          background: $s-green;
          border: 1px solid $s-green;
        }
      }

      li:last-child {
        float: right;
      }

      li {
        float: left;
        margin: 20px 10px;
        position: relative;
      }
    }
  }

  .usp-box {
    padding: 12px 6px 4px 6px;
    border-top: 2px solid $gray-200;
    border-bottom: 2px solid $gray-200;
    margin-bottom: 2rem;

    i {
      color: $s-green;
      display: inline-block;
      width: 80px;
      font-weight: 800;
    }
  }

  .solarplaza-logo {
    display: block;
    margin: 0 auto 30px auto;
    max-width: 280px;
    width: 90%;
  }
}

#steps {
  ol {
    counter-reset: item;
    list-style-type: none;
    padding-left: 2rem;

    li {
      color: $s-blue;
      margin-bottom: 0px;

      &.active {
        font-weight: bold;

        &:before {
          color: $white;
        }

        .step-mark {
          background: $s-blue;
          color: $white;
        }
      }

      &:not(:last-child):after {
        content: '';
        background: $s-blue;
        display: block;
        height: 40px;
        left: 4px;
        margin-bottom: -8px;
        position: relative;
        top: -1px;
        width: 1px;
      }

      .step-mark {
        border: 1px solid $s-blue;
        border-radius: 100px;
        display: inline-block;
        height: 25px;
        margin: 0 10px 0 -40px;
        position: relative;
        top: 7px;
        width: 25px;
      }
    }

    li::before {
      display: inline-block;
      content: counter(item) '';
      counter-increment: item;
      position: relative;
      width: 2em;
      z-index: 1;
    }
  }
}

.organization-select {
  .selectbox {
    position: relative;
    margin: 0.25rem 0 0.25rem 0;

    label {
      border: 2px solid $gray-300;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      margin-bottom: 0;
      padding: 5px 10px 5px 50px;
    }

    input[type='radio'] {
      background: $white;
      border: 1px solid $s-blue-light;
      border-radius: 100px;
      float: left;
      height: 16px;
      left: 20px;
      position: absolute;
      top: 24px;
      width: 16px;
      z-index: 100;
    }
    input[type='radio']::before {
      border: unset;
      border-radius: 100px;
      content: '';
      height: 8px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
    }
    input[type='radio']:checked::before {
      background: $s-green;
    }
    input[type='radio']:checked + label {
      background: $s-blue-muted;
      border: 2px solid $s-blue-light;
    }
    span {
      left: 0;
    }

    p {
      line-height: 15px;
      margin: 0 0 3px 0;
    }
  }
}

/* .search-results {
  background-color: $white;
  max-height: 300px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  box-shadow: 0 0.15rem 1.125rem rgba(0, 0, 0, 15%);
  border-radius: 0 0 3px 3px;

  li {
    align-items: center;
    border: 2px solid $gray-300;
    display: flex;
    height: 66px;
    list-style-type: none;
    padding-right: 7px;
    cursor: pointer;
    &:hover {
      background: $s-blue-muted;
    }

    div {
      align-items: center;
      display: flex;
      width: 100%;

      .logo {
        float: left;
        height: 50px;
        margin: 3px 10px 0 8px;
        width: 83px;

        img {
          width: 100%;
        }
      }

      p {
        line-height: 15px;
        margin: 0;
      }
    }
  }
} */

.confirmation-token {
  label {
    visibility: hidden;
  }
}

.password-box {
  margin: 0 auto;
  max-width: 300px;
}

.name-display {
  display: inline;
  padding: 5px;
}

.tutorial-popup {
  @include popup($white, $gray-800);
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
  max-width: 450px;
  padding: 20px;
  width: 100%;
  top: 22%;

  .btn-close {
    box-sizing: inherit;
    float: right;
    height: 10px;
    width: 10px;
  }
}

.report-box {
  float: right;

  a {
    padding: 3px 5px;
    &:hover {
      border-bottom: solid 1px;
    }
  }
}

.tutorial-popup {
  @include popup($white, $gray-800);
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
  max-width: 450px;
  padding: 20px;
  width: 100%;
  top: 20%;
}
.tutorial-close .close-btn {
  box-sizing: inherit;
  float: right;
  height: 10px;
  width: 10px;
}

.report-box {
  float: right;

  a {
    padding: 3px 5px;
    &:hover {
      border-bottom: solid 1px;
    }
  }
}

#resend-delay {
  display: none;
}

.event-ticket .ticket-section-left img {
  display: block;
  max-height: 60px;
  max-width: 500px;
  margin: 0;
}

.info-text {
  text-align: center;
}

.event-ticket {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-left: solid 1px $s-blue-light;
  border-radius: 10px;
  box-shadow: 0 0.15rem 1.5rem rgba(0, 0, 0, 0.1);
}
.ticket-section-left {
  padding: 1cm;
  margin: 0cm;
  min-height: 5cm;
  height: 100%;
}
.ticket-section-right {
  padding: 1cm;
  margin: 0cm;
  border-left: solid 1px $s-blue-light;
  min-height: 5cm;
  height: 100%;
}

.card-title {
  padding: 0rem;
  gap: 20px;
  width: 100%;

  .ticket-download-button {
    padding: 0px 2px;

    text-align: end;
  }
}

.partnership-box,
.attendee-box {
  background: $s-blue-muted;
  border: 2px solid $s-blue-muted;
  border-radius: 5px;
  margin-bottom: 2rem;

  .tag {
    border: unset;
    font-size: 0.8rem;
    margin: 0;
    padding: 0px 10px;

    &.large {
      font-size: 1rem;
      padding: 3px 12px;
    }

    &.yellow {
      background: $s-yellow-muted;
      color: $sun-dark;
      font-weight: 500;
    }

    &.green {
      background: $s-green-muted;
      color: $s-green;
      font-weight: 500;
    }

    &.blue {
      background: $s-blue-muted;
      color: $s-blue-light;
      font-weight: 500;
    }
  }

  i {
    font-size: 13px;
    margin: 0 3px 0 1px;
    position: relative;
  }

  .title {
    padding: 15px;
  }

  .attendee-info,
  .general_info {
    background: $white;
    border-bottom: 2px solid $s-blue-muted;
    padding: 15px;

    .deliverables {
      ul {
        list-style-type: none;
        padding-left: 0;
      }
    }

    hr {
      background-color: $s-blue-muted;
      border: none;
      height: 5px;
    }
  }

  .visual {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    .logo {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      left: 50%;
      padding: 5px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 60px;
      }
    }
  }

  .partnership-table {
    width: 100%;

    tr {
      background: $white;

      &.speaking-slot {
        td {
          background: $s-yellow-muted;
        }
      }

      th {
        background: $white;
        color: $s-blue-light;
        font-weight: bold;
        padding: 10px 40px 10px 15px;
      }
      td {
        padding: 15px 20px 15px 15px;
      }

      .number {
        padding-right: 15px;
        width: 90px;
      }

      &:nth-child(odd) {
        background: $gray-200;
      }

      &:last-child {
        td:first-child {
          border-bottom-left-radius: 5px;
        }
        td:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}

.password-eye {
  cursor: pointer;
  margin-left: -40px;
  color: $s-orange;
}

.errorlist {
  text-align: center;
  li {
    color: $s-red;
    font-size: 1rem;
    &::before {
      content: '!';
      padding: 0px 8px;
      margin-right: 5px;
      border-radius: 20px;
      background-color: $s-red;
      color: $white;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 576px) {
  .account-box {
    &.register {
      #id_kind {
        margin: 10px auto;
        text-align: center;
        li,
        li:last-child {
          display: inline-block;
          float: none;
          margin: 0 auto;
        }
      }
    }
  }
}
