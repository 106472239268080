.video-box {
  @include box($white, $gray-800);
  display: inline-flex;
  padding: 0px;

  iframe {
    border-radius: 20px;
    height: 400px;
    max-width: 100%;
    width: 720px;
  }
}
