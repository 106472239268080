.ticket-banner {
  background-image: linear-gradient(
      90deg,
      #030b45 11.02%,
      rgba(7, 27, 171, 0) 99.77%
    ),
    url('../static/images/events/registration-background.jpg');
  background-position: center 60%;
  border-radius: 5px;
  margin-bottom: 3rem;
  padding: 20px 40px;
}

.add-on-product {
  border-radius: 5px;
  border: 1px solid $gray-300;
  max-width: 500px;

  .visual {
    background-position: center center;
    background-size: cover;
    border-radius: 5px 0 0 5px;
  }

  .content {
    border-radius: 0 10px 10px 0;
    padding: 1rem;
  }
}

.product-selection,
.product-basket {
  display: inline-table;
  margin-bottom: 2rem;
  width: 100%;

  .attendee {
    padding: 0rem 1rem 0 1rem;
  }

  .product-row {
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px 0 1px 0;

    tr {
      vertical-align: middle;
    }

    &.summary {
      border-width: 0;

      .product-title {
        padding: 0.3rem;
      }
    }

    .product-title {
      padding: 1rem;
      width: 65%;
    }

    select {
      background: $gray-300;
      border: unset;
      border-right: 5px solid $gray-300;
      color: $s-blue;
      height: 35px;
      margin: 1rem 0 1rem 0;
    }

    .product-amount {
      text-align: center;
      min-width: 55px;
      width: 10%;
    }

    .product-price {
      padding: 0 1rem 0 1rem;
      text-align: right;
      white-space: nowrap;
      width: 25%;

      .discounted-price {
        color: $gray-500;
        text-decoration: line-through;
      }
    }
  }

  .product-total {
    border-top: 1px solid $gray-300;

    td {
      padding: 2rem 1rem 0 1rem;
    }
  }
}

.attendee-details {
  .form-box {
    @include box($white, $gray-800);
    padding: 25px 20px;
  }

  .add-on-box {
    position: relative;

    label {
      border: 1px solid $gray-300;
      border-radius: 5px;
      cursor: pointer;
      margin: 0;
      padding: 5px 5px 5px 50px;

      .visual {
        background-position: center center;
        background-size: cover;
        border-radius: 5px;
        min-height: 90px;
      }

      .text {
        margin-top: 10px;
      }
    }

    input[type='checkbox'] {
      background: $white;
      border: 1px solid $s-blue;
      border-radius: 3px;
      float: left;
      height: 20px;
      left: 35px;
      position: absolute;
      top: 22px;
      width: 20px;
      z-index: 100;
    }
    input[type='checkbox']::before {
      border: 1px solid $s-blue;
      border-radius: 3px;
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    input[type='checkbox']:checked::before {
      background-color: $white;
      content: '\f00c';
      color: $s-blue-light;
      font-family: FontAwesome;
      font-size: 1.1rem;
    }
    input[type='checkbox']:checked + label {
      background: $s-blue-muted;
      border: 1px solid $s-blue-light;
    }
    input[type='checkbox']:disabled {
      display: none;
    }
    input[type='checkbox']:disabled + label {
      cursor: unset;
      padding-left: 10px;
    }
    span {
      left: 0;
    }
  }

  .nav-tabs {
    border: unset;

    .nav-item {
      border: 0;

      button {
        background: $s-blue-muted;
        color: $s-blue-light;
        font-weight: bold;

        &.active {
          background: $s-blue-light;
          color: $white;
        }
      }
    }
  }
}

.payment-options {
  .selectbox {
    position: relative;

    label {
      border: 2px solid $gray-300;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      margin-bottom: 1rem;
      padding: 15px 20px 15px 50px;
    }

    input[type='radio'] {
      background: $white;
      border: 1px solid $s-blue-light;
      border-radius: 100px;
      float: left;
      height: 20px;
      left: 20px;
      position: absolute;
      top: 22px;
      width: 20px;
      z-index: 100;
    }
    input[type='radio']::before {
      border: unset;
      border-radius: 100px;
      content: '';
      height: 10px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
    }
    input[type='radio']:checked::before {
      background: $s-green;
    }
    input[type='radio']:checked + label {
      background: $s-blue-muted;
      border: 2px solid $s-blue-light;
    }
    span {
      left: 0;
    }
  }
}
