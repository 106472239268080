.top-banner {
  background: white;
  width: 100%;

  .main-title {
    padding: 1em;
    min-height: 225px;
    p {
      padding: 5px 0px 25px 0px;
      max-width: 630px;
      width: 100%;
    }
  }
}

.home-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 60vh;
  min-height: 400px;

  &.easy-banner {
    height: 50vh;
    min-height: 400px;
  }

  .svg-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    svg {
      width: 100%;
      height: 100%;
      display: block;
      z-index: -1;
    }
  }

  .easy-text {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;

    hr {
      color: $s-orange;
    }
  }

  .easy-visual {
    background-color: $s-orange;
    clip-path: circle(135px);

    img {
      clip-path: circle(135px);
      display: flex;
      max-height: 300px;
      width: auto;
    }
  }

  .main-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -110px;

    video {
      width: 50vw;
      height: 100%;
      display: block;
    }

    a i {
      color: white;
      font-size: 1.6rem;
      height: 20px;
      margin-top: 10px;
      padding: 15px 0px;
      text-align: center;
      transition: 0.2s;
      width: 20px;
      &:hover {
        color: $s-blue-light;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .easy-banner .easy-visual {
    clip-path: circle(145px);
    img {
      clip-path: circle(155px);
    }
  }
}
@media only screen and (max-width: 1200px) {
  .top-banner .main-title {
    padding: 1em;
    min-height: 250px;
  }
  .home-banner {
    max-height: 300px;
    .main-video video {
      width: 70vw;
    }
    &.easy-banner {
      height: 60vh;
      .svg-background {
        height: 100%;
      }
      .easy-visual {
        clip-path: circle(150px);
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        img {
          clip-path: circle(150px);
          margin: -22px;
          padding: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .top-banner .main-title {
    min-height: 300px;
  }
  .home-banner {
    min-height: 300px;
    height: 40vh;
    .main-video video {
      width: 85vw;
    }
    .easy-banner .easy-visual {
      display: none;
    }
  }
}

@media only screen and (max-width: 450px) {
  .top-banner .main-title {
    display: inline;
    min-height: unset;
    padding-top: 0;
  }
  .home-banner {
    height: 40vh;
    &.easy-banner {
      height: 65vh;
      min-height: 500px;
    }
    .svg-background {
      height: 85%;
    }

    .main-video a {
      margin-top: 20px;
      i {
        display: inline;
        font-size: 2rem;
        height: 40px;
        width: 40px;
        &:hover {
          margin-top: 20px;
        }
      }
    }
    .main-video video {
      margin-top: -10%;
      width: 85vw;
    }
  }
}
