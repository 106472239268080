@mixin banner($background-color, $min-height) {
  background: $background-color;
  display: block;
  min-height: $min-height;
  width: 100%;
}

.banner {
  &.blue-muted {
    @include banner($s-blue-muted, 0vh);
  }
}

.wrapper {
  display: block;
  padding: 10px;
  position: relative;
}

.notification {
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px;
  position: relative;

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

  &.yellow {
    background: #ffefce;
  }

  &.green {
    background: #d7e9d3;
  }

  &.red {
    border: 3px solid $s-red-muted;
    padding: 10px;
  }

  &.gray {
    background: $gray-100;
  }

  &.margin-n1 {
    margin-top: -1rem;
  }

  i {
    display: inline;
    margin-right: 5px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.feedback-banner {
  background: #cbe0f3;
  width: 100%;
  margin: -25px 0px -25px 0px;
  .title {
    color: $s-blue;
  }
}

.organization-banner {
  background: #ffefce;
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 20px;
  width: 100%;
  p {
    display: inline;
    .info {
      color: $sun-dark;
    }
  }

  .expand {
    border: solid 2px #ff9900;
    cursor: pointer;
    border-radius: 5px;
    float: right;
    margin-top: 4px;
    height: 26px;
    width: 32px;

    hr {
      margin: 3px;
    }

    .dropdown {
      color: $s-orange;
      padding: 2px 5px 2px 7px;
      transition: 0.3s;
      &:hover {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
      }
    }
  }
}
