body .main-nav {
  background: $white;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
  flex-direction: column;

  .navbar-toggler {
    border: none;
    float: left;
    padding: 5px 0 5px 10px;

    &:focus {
      box-shadow: unset;
    }

    .navbar-toggler-icon {
      background-image: none;
      color: $s-orange;
      width: unset;
      height: 0.5rem;

      i {
        font-size: 2rem;
      }
    }
  }

  .primary-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 3%;
    width: 100%;

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    a {
      &.nav-link {
        &.register {
          font-weight: 500;

          &:hover {
            border-bottom: 5px solid $white;
          }
        }

        &.login {
          border: none;
          color: $s-orange;
          font-size: 1.3rem;
          margin: 0.5em 0;
          position: relative;
          transition: 0.2s;
          white-space: nowrap;
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes fadeOut {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }

          .icon-line {
            animation: fadeIn 0.2s;
            display: inline;
            margin-right: 10px;
          }

          .icon-full {
            animation: fadeOut 0.2s;
            display: none;
            margin-right: 10px;
          }

          &:hover {
            .icon-line {
              display: none;
              animation: fadeOut 0.2s;
            }

            .icon-full {
              display: inline;
              animation: fadeIn 0.2s;
            }
          }
        }
      }
    }
  }

  .secondary-nav {
    background: $gray-200;
    color: $s-blue-light;
    display: flex;
    flex-direction: row;
    font-family: arboria;
    font-size: 1.3rem;
    font-weight: 400;
    justify-content: center;
    transition: 0.1s;
    width: 100%;
    z-index: 1;

    a {
      &.nav-link {
        border-bottom: 3px solid $gray-200;
        font-size: 1.1rem;
        margin: 0 2vw;
        padding: 10px 20px;

        &:hover {
          color: $s-orange;
          border-bottom: 3px solid $s-orange;
        }

        &.active {
          background: $s-blue-muted;
          border-bottom: 3px solid $s-blue-light;
          font-weight: bold;
        }
      }
    }
  }

  .navbar-brand {
    float: left;
    max-width: 220px;

    &.power-btn {
      width: 3%;
    }
  }

  .solarplaza-logo {
    margin: 0.5em 0;
    max-width: 220px;
    min-width: 120px;
    width: 100%;

    &.powerbutton {
      max-width: 30px;
      min-width: 30px;
    }

    &.anniversary {
      margin: 0 2em;
      max-width: 180px;
    }
  }

  .confetti-button {
    color: $sun-dark;
    position: absolute;
    left: 2%;
    top: 22%;

    .fa-bounce {
      height: 40px;
      width: 40px;
      animation-play-state: paused;

      &:hover,
      &:active,
      &:focus {
        animation-play-state: running;
      }
    }
  }

  .navbar-nav {
    padding-left: 2%;
  }

  .offcanvas {
    background-color: $gray-200;

    a,
    .nav-link {
      color: $s-blue-light;
      font-size: 1.8rem;
      line-height: 3rem;
    }

    .close-button {
      all: unset;
      font-size: 1.6rem;
      color: $gray-800;
      font-weight: 500;
    }

    .login {
      display: inline;

      i {
        font-size: 1.4rem;
      }
    }

    .navbar-nav.dropdown-toggle.user-nav {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;

      button {
        margin-right: 15px;

        i {
          font-size: 1.4rem;
        }
      }

      ul {
        background: none;
        border-style: none none none solid;
        border-radius: 0px;

        .dropdown-item {
          font-size: 1.4rem;
          color: $s-blue-light;
          text-decoration: underline;
        }
      }
    }
  }
}

.user-nav {
  display: flex;
  align-items: center;

  p {
    margin: 10px 10px 0px 10px;
  }

  button {
    background: none;
    border: solid 1px $s-orange;
    color: $s-orange;
    border-radius: 100px;
    font-size: 1.4rem;
    height: 44px;
    line-height: 2.5rem;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    text-transform: capitalize;
    width: 44px;

    &:hover,
    &:focus,
    &:active {
      background-color: $s-orange;
      border: solid 1px $s-orange;
    }
  }

  &.personal {
    text-align: center;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .messaging-button {
    border-right: solid 1px lightgray;

    a {
      line-height: 8px;

      span {
        font-size: 0.8rem;
      }
    }

    .badge {
      position: absolute;
      top: 0.6rem;
      left: 5rem;
      font-size: 8px;
      height: 16px;

      border-radius: 8px;

      background: $s-blue-light;
      color: $white;
    }

    margin-top: 0.6em;
    padding-top: 0.6em;
  }
}

.sub-nav {
  padding-top: 0;

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-toggler-icon {
    background-image: none;
    color: $s-blue;
    display: table-row;
    font-size: 1.3rem;
  }

  a {
    border-top: 3px solid $white;
    color: $gray-800;
    margin: 0 10px;
    padding: 10px;
    text-align: center;
    transition: 0.2s;
    width: fit-content;

    &:hover,
    &:active,
    &:focus,
    &.active {
      border-top: 3px solid $s-blue;
      color: $s-blue;
      font-weight: bold;
    }

    &:before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}

footer {
  background: white;

  .container {
    margin-bottom: unset;

    .navbar {
      align-items: unset;
      border-bottom: unset;

      .solarplaza-logo {
        margin: 15px 0px 0px 50px;
        width: 250px;
      }

      ul {
        list-style-type: none;
        margin: 0 0 15px 0;

        li {
          color: $s-blue;

          a {
            color: $s-blue;
          }
        }
      }
    }

    .legal-nav {
      border-top: 1px solid $gray-400;
      display: flex;
      margin-top: 20px;
      padding: 10px;
      text-align: center;

      ul {
        display: flex;
        list-style-type: none;

        li a {
          color: $s-blue;
          margin: 0 10px;
        }
      }
    }
  }
}

// not targeted properly

.search-dropdown-mobile {
  border-color: lightgray;
  border-style: none solid none none;
  border-radius: 50px 0px 0px 50px;
}

@media only screen and (max-width: 991px) {
  body .main-nav {
    .login-name {
      border-bottom: 5px solid white;
      color: $s-blue;
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 5px;
      transition: 1s;
    }

    .sub-nav {
      a {
        border-style: none;

        &:hover,
        &:active,
        &:focus,
        &.active {
          border-top: none;
        }
      }
    }
  }

  footer {
    .navbar {
      ul,
      .solarplaza-logo {
        width: 100%;
      }
    }
  }
}

// small screen styling
@media only screen and (max-width: 641px) {
  body .main-nav {
    justify-content: space-around;
    flex-direction: row;

    .primary-nav {
      display: contents;
    }

    .secondary-nav {
      display: none;
    }
  }
  .confetti-button {
    display: none;
  }
}
