// Main colors
$s-blue: #030b45;
$s-blue-light: #124491;
$s-blue-muted: #cbe0f3;
$s-orange: #f11800;
$s-orange-muted: #f9d5ca;

// Secondary colors
$sun-light: #ffb546;
$sun-medium: #ff9900;
$sun-dark: #ff6b00;

// Indicator colors
$s-green: #189a25;
$s-red: #dc3545;
$s-red-muted: #f8d7da;
$s-yellow-muted: #ffefce;
$s-green-muted: #d7e9d3;
//$s-green-muted: #d8eacc;  ?

// Gradients
$s-orange-gradient: linear-gradient(88deg, $s-orange 1.3%, $sun-dark 119.35%);

// Venture colors
$s-academy: #3ec2a3;
$s-consultancy: #073763;
$s-foundation: #8cc0c5;

$s-panel-border-radius: 24px;

$s-box-shadow: 0.1rem 0.5rem 0.5rem -0.5rem #444;
$s-box-shadow-light: 0 0.15rem 1.5rem rgba(0, 0, 0, 0.1);
$s-box-shadow-basic: 0 0.15rem 1.5rem rgba(0, 0, 0, 0.15);
$s-box-shadow-heavy: 0 0.15rem 1.5rem rgba(0, 0, 0, 0.25);

:root {
  --s-blue: #{$s-blue};
  --s-orange: #{$s-orange};
  --s-green: #{$s-green};
}
