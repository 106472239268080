:root,
body {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
li,
label,
input,
select,
textarea,
a,
th,
td,
.btn-link,
span,
section {
  border: unset;
  color: $gray-800;
  font-family: arboria, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  padding: unset;
  text-decoration: none;

  &.display-1,
  &.display-2,
  &.display-3,
  &.display-4,
  &.display-5,
  &.display-6,
  &.display-7,
  &.display-8,
  &.display-9 {
    font-weight: 400; // override Bootstrap default font-weight
  }

  i {
    font-size: 1rem;
    position: relative;
    text-align: center;
    width: 20px;

    &.small {
      font-size: 0.8rem;
      top: -0.1rem;
      width: 14px;
    }

    &.align-left {
      margin-right: 10px;
    }

    &.align-right {
      margin-left: 10px;
    }
  }

  &.white,
  &.white > p {
    color: $white;
  }

  &.orange,
  &.orange > p {
    color: $s-orange;
  }

  &.blue,
  &.blue > p {
    color: $s-blue;
  }

  &.blue-light,
  &.blue-light > p {
    color: $s-blue-light;
  }

  &.blue-muted,
  &.blue-muted > p {
    color: $s-blue-muted;
  }

  &.sun-light,
  &.sun-light > p {
    color: $sun-light;
  }

  &.sun-medium,
  &.sun-medium > p {
    color: $sun-medium;
  }

  &.sun-dark,
  &.sun-dark > p {
    color: $sun-dark;
  }

  &.gray-light,
  &.gray-light > p {
    color: $gray-500;
  }

  &.bold,
  &.bold > p {
    font-weight: 600;
  }

  &.italic,
  &.italic > p {
    font-style: italic;
  }

  &.light,
  &.light > p {
    font-weight: 300;
  }

  &.margin-0,
  &.margin-0 > p {
    margin-bottom: 0rem;
  }

  &.margin-2,
  &.margin-2 > p {
    margin-bottom: 2rem;
  }

  &.display-1,
  &.display-1 > p {
    font-size: 3rem;
    line-height: 3.2rem;
  }

  &.display-2,
  &.display-2 > p {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }

  &.display-3,
  &.display-3 > p {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  &.display-4,
  &.display-4 > p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &.display-5,
  &.display-5 > p {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  &.display-6,
  &.display-6 > p {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  &.display-7,
  &.display-7 > p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  &.display-8,
  &.display-8 > p {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  &.display-9,
  &.display-9 > p {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }

  &.large,
  &.large > p {
    font-size: 1.1rem;
  }

  &.small,
  &.small > p {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  &.x-small,
  &.x-small > p {
    font-size: 0.7rem;
    line-height: 0.7rem;
  }

  &.inline,
  &.inline > p {
    display: inline;
    margin-bottom: 0rem;
  }

  &.lineheight-small {
    line-height: 1rem;
  }

  &.center {
    margin: 0px auto;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }
}

ul {
  margin-bottom: 0;

  li {
    margin-bottom: 0;
  }

  &.list-type-none {
    list-style-type: none;
  }

  &.disc {
    list-style-type: disc;
    margin-left: 2rem;
  }
}

a,
.btn-link {
  color: $s-orange;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &.black {
    color: $gray-800;
  }

  span {
    transition: 0.2s ease-in-out;
  }

  &:hover {
    color: $sun-light;

    span {
      color: $sun-light;
    }
  }

  &.sun-light {
    color: $sun-light;

    :hover {
      color: $s-orange;
    }
  }
}

.click {
  cursor: pointer;
}

.green {
  color: $s-green;
}

.red {
  color: $s-red;
}

.consultancy-color {
  color: $s-consultancy;
}

.academy-color {
  color: $s-academy;
}

.foundation-color {
  color: $s-foundation;
}

@media only screen and (max-width: 1200px) {
  :root,
  body {
    font-size: 14px;
  }
}
