.toast-messages {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 300px;

  @include media-breakpoint-down(sm) {
    width: calc(100vw);
  }

  .alert {
    //Shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-width: 2px;
    color: $s-blue;
    background-color: $white;

    &.alert-danger {
      border-color: $s-red;
      background-color: $white;
    }

    &.alert-success {
      border-color: $s-green;
      background-color: $white;
    }
    &.alert-info {
      border-color: $s-blue;
      background-color: $white;
    }
    &.alert-warning {
      border-color: $s-red-muted;
      background-color: $white;
    }
  }
}
