.dashboard-hero {
  background: $s-orange;
  min-height: 15vh;
  max-height: 500px;
  position: relative;
}

.dashboard-tile {
  background-color: white;
  border: 2px solid $gray-200;
  border-radius: 5px;
  min-height: 250px;
  padding: 40px;
  position: relative;

  i {
    font-size: 1.4rem;
    margin-right: 10px;
  }
}

.order-table {
  display: inline-table;
  margin-bottom: 2rem;
  max-width: 1000px;
  width: 100%;

  th {
    padding: 10px;
  }

  .order-row {
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px 0 1px 0;

    td {
      padding: 10px;

      i {
        margin-right: 0.2rem;
      }
    }
  }
}
