.badges {
  .badge-container {
    position: relative;
    text-align: center;
    width: 65px;
  }
  .detail {
    width: 80px;
    margin: 5px;
  }
  .badge-text {
    display: flex;
    justify-content: center;
    &.overview {
      font-size: 1rem;
      flex-wrap: wrap;
      align-content: space-evenly;
      justify-content: flex-start;
      height: 100%;
      margin: 0 0 0 -5px;
    }
  }
  .number-text {
    font-size: 2.3rem;
    font-weight: 600;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.overview {
      font-size: 1rem;
    }
  }

  // Search overview styling
  .search-overview {
    width: 32px;
    margin: 1px;
  }
  .badge-layout {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 6px;
  }

  .lvl-1 {
    fill: white;
    stroke: #ffb546;
    stroke-miterlimit: 10;
    stroke-width: 10px;
  }
  .lvl-2 {
    fill: white;
    stroke: #ff6b00;
    stroke-miterlimit: 10;
    stroke-width: 10px;
  }
  .lvl-3 {
    fill: white;
    stroke: $s-orange;
    stroke-miterlimit: 10;
    stroke-width: 10px;
  }
  .sun-1 {
    opacity: 0.3;
    fill: #ffb546;
  }
  .sun-2 {
    opacity: 0.3;
    fill: #ff6b00;
  }
  .sun-3 {
    opacity: 0.3;
    fill: $s-orange;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: $gray-800;
    display: flex;
    text-align: center;
    padding: 5px 0;
    border-radius: 5px;
    box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
    width: 230px;
    opacity: 0;
    transition: opacity 1s;
    white-space: normal;
    left: -85%;
    bottom: 90%;
    position: absolute;
    z-index: 100;
  }
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .badge {
    align-items: center;
    flex-direction: row;
    float: none;
    padding: 20px 20px 20px 0px;
    width: 100%;
  }
}
