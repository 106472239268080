// Override Bootstrap's card variables
$card-border-width: 0; // Change the card border width
$card-border-radius: 1rem; // Change the card border radius
$card-spacer-x: 1.5rem; // Change the card horizontal padding
$card-spacer-y: 1.5rem; // Change the card vertical padding
$card-shadow: 0 0.5rem 1rem; // Change the card shadow

.card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
